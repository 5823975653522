// Global
import {
  Image as JSSImage,
  ImageField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import NextImage from 'next/image';
import Head from 'next/head'; // Import Head from next/head

// Lib
import { FocusAreaValue, LayoutValue } from '.';
import useNormalMode from 'lib/utils/use-normal-mode';
import { useExperienceEditor } from 'lib/utils';
import { isSvgUrl } from 'lib/utils/string-utils';
import { useTheme } from 'lib/context/ThemeContext';
import { customImageLoader } from './imageLoader';

/**
 * JSS does not yet support Next Image in Exprience Editor
 * This component will switch between the two based on environment
 * which allows us to get the various performance benefits from Next Image
 *
 * Note that the images may display slightly differently in
 * Experience Editor as the JSS Image component doesn't have the same layout options
 */

export type ImageProps = {
  image?: ImageField;
  layout?: LayoutValue;
  focus?: FocusAreaValue;
  generateSchemaMarkup?: boolean;
};

const Image = ({
  image,
  layout = 'responsive',
  focus = 'center',
  generateSchemaMarkup = true,
}: ImageProps): JSX.Element => {
  const { themeName } = useTheme();
  const isEE = useExperienceEditor();
  const isNormalMode = useNormalMode();
  const { sitecoreContext } = useSitecoreContext();
  // If we're in EE, we still want to render the image for editing, even when it's empty.
  if (!image?.value?.src && !isEE) {
    return <></>;
  }
  const imageValue = image?.value;
  const isSvg = !!imageValue && !!imageValue.src && isSvgUrl(imageValue.src);

  // Fix for local docker.
  if (process.env.NEXT_PUBLIC_EW_ENVIRONMENT == 'Local' && imageValue) {
    imageValue.src = imageValue.src?.replace('https://cm/', '/');
  }
  function urlUpdate(url: string): string {
    const baseUrl = sitecoreContext.extendedSiteInfo?.targetHostName
      ? 'https://' + sitecoreContext.extendedSiteInfo?.targetHostName
      : process.env.PUBLIC_URL;

    const sitecorePattern = /^https:\/\/edge\.sitecorecloud\.io\/andersencorporation-[^/]+\//;
    if (sitecorePattern.test(url)) {
      url = url.replace(sitecorePattern, '/-/');
    }
    const imageUrl = `${baseUrl}${url}`;
    return imageUrl;
  }
  const ldJsonScriptImage = {
    '@context': 'https://schema.org',
    '@type': 'ImageObject',
    contentUrl: imageValue?.src ? urlUpdate(imageValue?.src) : imageValue?.src,
    creator: 'Andersen Windows',
  };

  return (
    <>
      {generateSchemaMarkup &&
        themeName === 'aw' && ( // Include the generateSchemaMarkup check
          <Head>
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJsonScriptImage) }}
            />
          </Head>
        )}

      {isNormalMode ? (
        <NextImage
          loader={customImageLoader}
          src={`${imageValue?.src}`}
          alt={`${imageValue?.alt}`}
          width={`${imageValue?.width}`}
          height={`${imageValue?.height}`}
          layout={layout}
          objectPosition={focus}
          objectFit="cover"
          unoptimized={isSvg}
        />
      ) : (
        <JSSImage
          field={image}
          style={{
            objectFit: 'cover',
            objectPosition: focus,
          }}
        />
      )}
    </>
  );
};

export default Image;
